import React, {useContext, useEffect} from 'react';
import classes from './PaymentPlan.module.css';
import paypal from './../../img/paypal.svg';
import CreditCard from './../../img/CreditCard.svg';
import {useState} from 'react';
import {useMutation, useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {AuthContext} from '../../context/authContext';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import Spinner from '../spinner/Spinner';
import {useSearchParams} from 'react-router-dom';

const PaymentPlan = () => {
    const [processing, setProcessing] = useState(false);
    const [pkgId, setPkgId] = useState(null);
    const [cardType, setCardType] = useState('');
    const token = Cookies.get('token');
    const [activeClass, setActiveClass] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const {currentUser} = useContext(AuthContext);

    const {data, isLoading} = useQuery({
        queryKey: ['packages'],
        queryFn: () => axios.get('https://admin.watchturks.online/api/packages'),
        staleTime: 0,
        cacheTime: 0,
    });

    useEffect(() => {
        const planId = searchParams.get('plan_id');
        if (planId) {
            selectPackage(planId, false);
        }
    }, [searchParams]);

    const stripePaymentMethod = useMutation({
        mutationFn: async () => {
            try {
                const userId = currentUser?.body.user.user_id;
                if (!userId) throw new Error("User ID not found.");
                if (!pkgId) throw new Error("Please select a plan.");

                const form = new FormData();
                form.set("user_id", userId);
                form.set("package_id", pkgId);

                const response = await axios.post(
                    `https://admin.watchturks.online/api/subscriptions/${userId}/${pkgId}/stripe/create-checkout-session`,
                    form
                );

                return response.data;
            } catch (error) {
                throw new Error(error.response?.data?.message || "An error occurred while processing your payment.");
            }
        },
        onSuccess: (data) => {
            window.location.href = data.session_url;
            setProcessing(false);
        },
        onError: (error) => {
            setProcessing(false);
            toast.error(error.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        },
    });


    const selectPackage = (id, change = true) => {
        if (change) {
            searchParams.set('plan_id', id);
            setSearchParams(searchParams);
        }
        setPkgId(id);
        setActiveClass(id);
    };

    const submitHandler = (e) => {
        e.preventDefault();
        setProcessing(true);

        if (!pkgId || !cardType) {
            toast.warn('Please make a choice', {
                position: 'bottom-left',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            setProcessing(false);
            return;
        }

        // if (cardType === 'PayPal') handlePaypalPayment();
        if (cardType === 'Stripe') handleStripePayment();
    };

    // function handlePaypalPayment() {
    //     paypalPaymentMethod.mutate();
    // }

    function handleStripePayment() {
        stripePaymentMethod.mutate();
    }

    const currentPaymentMethod = currentUser?.body.user.user_plan.payment_method;

    return (
        <div className={classes.paymentPlanTop}>
            <h1>Please Choose A Plan</h1>
            <p className={classes.paraTop}>You can update your plan or change to another one</p>
            {currentPaymentMethod && (
                <p className={classes.warningMessage}>To downgrade your subscription, please cancel your current plan or
                    wait for the duration to expire.</p>
            )}
            <div className={classes.row}>
                {isLoading ? (
                    <Spinner/>
                ) : (
                    data?.data.body.packages.map((pkg) => {
                        const isCurrentOrLowerPlan = currentUser?.body.user.user_plan.plan_id >= pkg.id;

                        const uniqueKey = `package-${pkg.id}-${new Date().getTime()}`;

                        return (
                            <div
                                key={uniqueKey} // Benzersiz key burada
                                className={`${classes.bronze} ${isCurrentOrLowerPlan ? classes.disabled : ''}`}
                                onClick={!isCurrentOrLowerPlan ? () => selectPackage(pkg.id) : null}
                                style={{border: activeClass == pkg.id ? '1px solid rgb(19, 157, 224, 0.45)' : ''}}
                            >
            <span className={classes.inner} onClick={!isCurrentOrLowerPlan ? () => selectPackage(pkg.id) : null}>
                {currentUser?.body.user.user_plan.plan_id === pkg.id && (
                    <div className={classes.badge}>Your Plan</div>
                )}
                <h2>{pkg.title}</h2>
                <p>
                    ${parseInt(pkg.amount)}{' '}
                    <span>
                        /{pkg.duration}{' '}
                        {pkg.type === 'days'
                            ? 'days'
                            : pkg.type === 'monthly' && pkg.duration > 1
                                ? 'months'
                                : pkg.type === 'monthly' && pkg.duration == 1
                                    ? 'month'
                                    : pkg.type === 'yearly'
                                        ? 'year'
                                        : ''}
                    </span>
                </p>
            </span>
                            </div>
                        );
                    })
                )}
            </div>
            <div className={classes.paymentMethod}>
                <h1>Select payment method</h1>
                <div className={classes.rowCard}>
                    {/*{(currentPaymentMethod === 'paypal' || !currentPaymentMethod) && (*/}
                    {/*    <button onClick={() => setCardType('PayPal')} style={{ border: cardType === 'PayPal' ? '1px solid rgb(19, 157, 224, 0.45)' : '' }}>*/}
                    {/*        <span>*/}
                    {/*            <img src={paypal} alt="paypal" /> Paypal*/}
                    {/*        </span>*/}
                    {/*    </button>*/}
                    {/*)}*/}

                    {(currentPaymentMethod === 'stripe' || !currentPaymentMethod) && (
                        <button onClick={() => setCardType('Stripe')}
                                style={{border: cardType === 'Stripe' ? '1px solid rgb(19, 157, 224, 0.45)' : ''}}>
                            <span>
                                <img src={CreditCard} alt="CreditCard"/>
                                Credit Card
                            </span>
                        </button>
                    )}
                </div>


                <button className={classes.payBtn} onClick={submitHandler} disabled={processing} type="submit"
                        style={{opacity: processing ? '.4' : '1'}}>
                    Pay & Enter
                </button>
            </div>
            <ToastContainer limit={1}/>
        </div>
    );
};

export default PaymentPlan;
